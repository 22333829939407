import axios from 'axios'
import VueAxios from 'vue-axios'
import config from "../config/config.env";
import {App} from "@vue/runtime-core";
import {AxiosBaseResponse} from "@/types/response";


const grep = /^(\/)?(system|risk|logistics|archives|record|after)\//;
axios.defaults.baseURL = config.baseURL;
axios.defaults.headers.common.token=sessionStorage.getItem("token")||'';
axios.interceptors.request.use(config => {
    console.log(config);
    let url = config.url || '';
    if (url.startsWith("/")) {
        url = url.substring(1);
    }
    if (!grep.test(url)) {
        config.url = "pc/" + url;
    }
    return config;
})

export default (app: App): void => {
    app.use(VueAxios, axios);
    app.config.globalProperties.$showErr = function (res: AxiosBaseResponse) {
        if (res.data.code == 0) {
            return false;
        } else {
            this.$alert(res.data.msg, "错误");
            return true;
        }
    }
}
